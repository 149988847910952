.main-container-navbar {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
}

.left-column-navbar {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-left: 20px
}

.left-column-navbar img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
}

.left-column-navbar h1 {
    font-size: 1.2rem;
    text-align: left;
    font-weight: 500;
    padding: 0 10px;
    margin: 0;
    cursor: pointer;
    color: white;
}

.right-column-navbar {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0;
    margin-right: 20px
}

.nav-items {
    height: auto;
    width: 80%;
    display: flex;
    justify-content: space-around;
    text-align: left;
    align-items: flex-end;
}

.right-column-navbar ul li {
    list-style-type: none;
}

.right-column-navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.right-column-navbar a {
    color: black;
    text-decoration: none;
    font-weight: 500;
}


.right-column-navbar a:hover {
    color: rgb(91, 89, 89);
    text-decoration: underline;
    cursor: pointer;
}
.hamburger-menu-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: auto;
}

.hamburger-menu-items ul {
    margin: 0;
    padding: 0;
}

.hamburger-menu-items a {
    color: white;
   text-decoration: none;
   font-size: 1.5rem;
}

.hamburger-menu-items li ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}


.hamburger-menu-items  li {
    height: 10%;
    text-align: center;
    margin: 15px;
    cursor: pointer;
    font-size: 1.5rem;
}


.hamburger-menu-ul li  {
    padding: 0;
    margin: 15px 0 0 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: rgb(122, 115, 115);
    font-size: 1rem;
}

.hamburger-menu-ul a {
    color: grey;
    font-size: 1.2rem;
}


@media (max-width: 767px) {
    .left-column-navbar h1 {
        display: none;
    }

    .left-column-navbar {
        justify-content: flex-start;
        padding: 0;
    }
        .hamburger-menu-items ul {
            color: rgb(122, 115, 115);
        }
}