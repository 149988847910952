.main-container-groups {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    height: auto;
    margin: 0 0 50px 0;
}

.heading {
    width: 90%;
    height: 40vh;
    display: flex;
    flex-direction: row;
    padding: 0;
    border-bottom: 5px;
    border-width: 5px;
    margin: 40px 0;
}

.heading-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.heading-column h1 {
    font-size: 3.8rem;
    font-weight: 600;
    width: 90%;
}

.heading-column h3 {
    font-size: 1.35vw;
    font-weight: 300;
    color: grey;
}

.groups {
    width: 80%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    margin: 10px 0 0px 0;
    padding-top: 30px;
    padding-bottom: 30px;
}

.groups img {
    object-fit: cover;
    border-radius: 8px;
    width: 50%;
}

.groups-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    flex-direction: column;
}

.groups-content h1 {
    font-size: 4vw;
    font-weight: 500;
    margin: 0;
    padding: 0;
    width: 80%;
    margin-bottom: 5px;
    border-bottom: solid .5px #aca9a9;
    display: inline;
    padding-bottom: 3px;
    text-align: center;
}

.groups-content p {
    font-size: 1.2vw;
    font-weight: 300;
    margin: 5px 0 0 0;
    padding: 0;
    color: grey;
    width: 80%;
    text-align: justify;
}

.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 1em;
    border-radius: 5px;
    margin-top: 30px;
}

.form-container h1 {
    font-size: 2.7rem;
    text-align: center;
    font-weight: 500;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.form-groups {
    flex: 1;
    margin-right: 1em;
}

.form-groups:last-child {
    margin-right: 0;
}

.form-groups label {
    display: block;
    margin-bottom: 0.5em;
}

.form-groups input,
.form-groups select {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline-color: black;
}

.form-groups button {
    padding: 0.75em 1.5em;
    color: #fff;
    background-color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 30px 0;
    font-weight: 500;
}

.form-groups button:hover {
    background-color: green;
}

@media (max-width: 1024px) {
    .heading {
        width: 90%;
        height: 20vh;
        display: flex;
        flex-direction: column;
        margin: 10px 0
    }

    .heading-column {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .heading-column h3 {
        font-size: 1.7vw;
        display: none;
    }

    .groups-content {
        width:  100%;
    }
    .groups-content h1 {
        font-size: 2.5rem;
        font-weight: 500;
        width: 80%;
    }

    .groups-content p {
        font-size: 1rem;
    }

    .groups img {
        height: 100%;
    }
    .groups {
        height: 40vh;
        width: 90%;
    }
}

@media (max-width: 767px) {
    .main-container-groups {
        margin-bottom: 50px;
    }
    .heading {
        height: 35%;
    }

    .groups {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px 0;
    }

    .groups img {
        object-fit: cover;
        border-radius: 8px;
        width: 90%;
    }

    .groups-content {
        justify-content: center;
        align-items: flex-start;
        width: 90%;
        flex-direction: column;
    }

    .groups-content h1 {
        font-size: 1.8rem;
        font-weight: 500;
        width: 100%;
        text-align: left;
        padding: 20px 0 0 0;
    }

    .groups-content p {
        font-size: 1rem;
        width: 100%;
    }

    .heading-column h3 {
        font-size: 2.8vw;
    }

    .heading-column h1 {
        font-size: 2.3rem;
        width: 100%;
    }
}

@media ((max-height: 767px) and (orientation: landscape)) {

    .heading {
        height: 200px;
        width: 80% !important;
    }

    .heading-column  { width: 100%;}

    .groups {
        width: 80%;
        height: 450px;
        margin: 0 0 30px 0;
    }

    .heading {
        width: 70%;
    }
}