.main-container-community {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 25px 0
}

.community-heading-and-pictures {
    width: 90%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.heading-community {
    width: 28%;
    display: flex;
    flex-direction: column;
}

.heading-community h1 {
    font-size: 5vw;
    margin: 0;
    padding: 0;
}

.heading-community p {
    font-size: 1.3vw;
    color: grey;
    font-weight: 300;
}

.community-heading-and-pictures img {
    height: 80%;
    width: 30%;
    object-fit: cover;
    border-radius: 12px;
}

.community-pictures {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 30%;
    height: 100%;
    flex-direction: column;
}

.community-pictures img {
    height: 40%;
    width: 100%;
    border-radius: 0px;
}

.community-outreach-programs {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.outreach-program {
    width: 42%;
    height: 100%;
    margin: 20px 0;
}

.outreach-program img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

.outreach-program h1 {
    font-size: 2.5vw;
    padding: 8px 0px 4px 0px;
    margin: 0;
}

.outreach-program p {
    font-size: 1.15vw;
    color: rgb(146, 144, 144);
    font-weight: 300;
    padding: 8px 0px;
    margin: 0;
}

.outreach-program button {
    font-size: 1vw;
    background-color: transparent;
    border: 1px solid grey;
    background-color: white;
    color: black;
    padding: 10px 12px;
    font-size: 1vw;
    cursor: pointer;
    margin-top: 8px;
    width: 150px;
    font-weight: 600;
    border-radius: 12px;
}

.outreach-program button:hover {
    background-color: rgb(241, 239, 239);
}

.bridge-content {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 0px 60px 0px;
    padding: 30px;
}

.bridge-content h3 {
    text-align: center;
    font-size: 1.2vw;
    width: 60%;
    margin: 0;
    padding: 0;
    color: grey;
    font-weight: 300;
}

.bridge-content h1 {
    text-align: center;
    font-size: 3.5vw;
    margin: 0;
    padding: 30px;
    width: 80%;
}


@media (max-width: 1024px) {
    .heading-community {
        width: 35%;
        display: flex;
        flex-direction: column;
    }

    .community-pictures {
        display: none;
    }

    .community-heading-and-pictures img {
        height: 90%;
        width: 55%;
        object-fit: cover;
        border-radius: 12px;
    }

    .community-heading-and-pictures {
        height: 50vh;
    }

    .heading-community h1 {
        font-size: 6vw;
        margin: 0;
        padding: 0;
    }

    .heading-community p {
        font-size: 2vw;
    }

    .bridge-content h3 {
        font-size: 2.3vw;

        width: 90%;
    }

    .bridge-content h1 {
        font-size: 5vw;
        width: 90%;
    }

    .community-outreach-programs {
        margin-top: 10px;
        width: 90%;
    }

    .outreach-program {
        height: 100%;
    }

    .outreach-program img {
        width: 100%;
        height: 50%;
        object-fit: cover;
        border-radius: 8px;
    }

    .outreach-program h1 {
        font-size: 3vw;
        padding: 8px 0px 4px 0px;
        margin: 0;
    }

    .outreach-program p {
        font-size: 1.8vw;
        color: rgb(146, 144, 144);
        font-weight: 300;
        padding: 8px 0px;
        margin: 0;
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    .community-heading-and-pictures {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .heading-community {
        display: none;
    }

    .community-heading-and-pictures img {
        width: 100%;
    }
.bridge-content {
    margin: 20px 0;
    padding: 0;
}

    .community-outreach-programs {
        width: 90%;
    }

    .outreach-program {
        height: 80vw;
        width: 100%;
        margin: 10px 0;
    }

    .outreach-program img {
        height: 50vw;
    }

    .outreach-program h1 {
        font-size: 3rem;
        width: 80%;
    }

    .outreach-program p {
        font-size: 1rem;
        width: 90%;
    }

    .outreach-program button {
        font-size: 1rem;
    }
}

@media screen and (max-width: 599px) {
.community-outreach-programs {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .outreach-program {
        width: 90%;
    }

         .outreach-program img {
             height: 60vw;
         }
    
         .outreach-program h1 {
             font-size: 1.8rem;
             width: 100%;
         }
    
         .outreach-program p {
             font-size: 1rem;
             width: 100%;
         }
    
         .outreach-program button {
             font-size: .8rem;
         }
         .community-heading-and-pictures {
            display: none;
         }
         .bridge-content {
            padding: 0;
            margin: 0 0 20px 0;
            width: 90%;
            justify-content: flex-start;
            align-items: flex-start;
         }
         .bridge-content h1 {
            width: 90%;
            text-align: left;
            margin: 0;
            padding: 10px 0;
            font-size: 2.2rem;
         }
         .bridge-content h3 {
            font-size: 1rem;
            width: 100%;
            text-align: justify;
            padding: 10px 0;
         }
}
