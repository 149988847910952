.main-container {
  position: relative;
  height: 100vh;
  /* Set the height of the container to the viewport height */
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /* Place the video behind other content */
  filter: brightness(50%);
}

.content {
  position: absolute;
  top: 30%;
  color: #fff;
  padding: 5%;
}

.content h1 {
  font-size: 6.5rem;
  width: 50%;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.content h2 {
  font-size: 2.5em;
  padding: 0;
  margin: 0;
  color: #ebebeb;
  font-weight: 300;
}

.content h3 {
  font-weight: 300;
  color: #dad8d8;
  font-size: 1rem
}

/* This should take care of the landscape as well for devices
  that are wider than tall for mid-tier tablets */

@media screen and (max-width: 1023px) {
  .content {
    top: 50%;
  }

  .content h1 {
    font-size: 6.5rem;
    width: 80%;
  }

  .content h2 {
    font-size: 2.5rem;
  }

  .content h3 {
    font-size: 1rem
  }
}

@media screen and (max-width: 599px) {
  .content {
    top: 50%;
  }

  .content h1 {
    font-size: 8vh;
    width: 70%;
  }

  .content h2 {
    font-size: 3.8vh;
    color: rgb(192, 190, 190);
  }

  .content h3 {
    font-size: 2vh;
    color: rgb(177, 177, 177);
  }
}

@media screen and (max-height: 599px) and (orientation: landscape){
  .main-container {
      height: 100vh;
    }
  
  .content {
    top: 20%;
  }

  .content h1 {
    font-size: 8vw;
    width: 50%;
  }

  .content h2 {
    font-size: 3.8vw;
    color: rgb(192, 190, 190);
  }

  .content h3 {
    font-size: 2vw;
    color: rgb(177, 177, 177);
  }
}
