.main-container-sermons {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.left-column-sermons {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right-column-sermons {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sermon {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: none;
    border-radius: 8px;
}

.sermon-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
}

.sermon-title h1 {
    font-size: 4rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
    color: black;
    text-align: right;
    width: 100%;
    padding: 20px 0;
}

.sermon-title p {
    font-size: 1.35vw;
    font-weight: 300;
    color: grey;
    text-align: justify;
    margin: 0;
    padding: 0;
}

.button-submit-sermon {
    width: 100%;
    color: white;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 0;
    padding: 0;
}

.button-submit-sermon a {
    text-decoration: none;
    color: white;
    width: 35%;
    padding: 0px !important;
    margin-top: 30px;
}

.button-submit-sermon button {
    height: 40px;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 1rem;
    background-color: black;
    border: 0;
    cursor: pointer;
    font-weight: 530;
    border-radius: 4px;
    margin: 0;
    padding: 0px 10px;
}

.button-submit-sermon button:hover {
    background-color: green;
}

@media (max-width: 1024px) {
    .main-container-sermons {
        height: 40vh;
        column-gap: 0;
        justify-content: space-around;
    }

    .left-column-sermons {
        width: 40%;
        align-items: center;
    }

    .right-column-sermons {
        width: 50%;
    }

    .sermon-title h1 {
        font-size: 6.5vw;
        font-weight: 500;
        padding: 0;
        margin: 0;
        color: black;
        text-align: right;
        margin-bottom: 10px;
    }

    .sermon-title a {
        color: black;
        height: 20%;
        width: 70%;
        padding-top: 10px;
    }

    .sermon-title p {
        font-size: 1.9vw;
    }

    .button-submit-sermon a {
        padding: 0px !important;
        margin-top: 5px;
    }


    /* .button-submit-sermon {
        height: 10px
    } */
}

@media (max-width: 1024px) and (orientation: landscape) {
    .main-container-sermons {
        height: 50vw;
        margin-top: 0;
    }
}

@media (max-width: 599px) {
    .main-container-sermons {
        height: 550px;
        flex-direction: column;
        margin: 30px 0;
    }

    .left-column-sermons {
        width: 90%;
        height: auto;
        padding: 0;
        margin: 0;
    }

    .right-column-sermons {
        width: 90%;
    }

    .sermon-title {
        width: 100%;
    }

    .sermon-title h1 {
        font-size: 10vw;
        text-align: left;
        width: 100%;
        padding: 10px 0;
        margin: 0;
    }

    .sermon-title p {
        font-size: 4vw;
        width: 100%;
        text-align: left;
        padding: 0;
    }

    .button-submit-sermon {
        width: 100%;
        justify-content: left;
        padding: 10px 0;
        height: 30px;
    }

    .button-submit-sermon a {
        text-decoration: none;
        color: white;
        width: 35%;
    }

    .button-submit-sermon button {
        font-size: 3.5vw;
    }
}

@media ((max-height: 559px) and (orientation: landscape)) {
    .main-container-sermons {
        height: 50vw;
        flex-direction: row;
        width: 100%;
        padding: 0;
    }

    .right-column-sermons {
        width: 45%;
    }

    .sermon-title h1 {
        margin: 5% 0;
        text-align: right;
    }

    .sermon-title p {
        font-size: 4vh;
    }

    .button-submit-sermon {
        margin: 0;
        padding: 0;
    }
}