.main-container-events-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
}

.content-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.content-card :hover {
    background-color: rgb(224, 223, 223);
    cursor: pointer;
}

.image-title {
    width: 80%;
    height: 60vh;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    flex-direction: row;
}

.titles {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titles h1 {
    width: 100%;
    font-size: 7vw;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.titles h2 {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    color: grey;
}

.images-box {
    display: flex;
    justify-content: space-around;
    width: 50%;
    height: 100%;
    align-items: center;
}

.double-images {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.double-images img {
    height: 48%;
    border-radius: 8px;
    object-fit: cover;
}

.single-image {
    width: 50%;
    height: 100%;
}

.single-image img {
    width: 90%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.event-card {
    width: 70%;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.event-card img {
    width: 25%;
    object-fit: contain;
}

.event-details {
    padding: 0 0 0 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.event-details h2 {
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    color: black;
}

.event-details button {
    width: 20%;
    margin-top: 10px;
}

.event-details button:hover {
    background-color: black;
}

.event-link {
    text-decoration: none;
}

.event-details h4 {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    padding: 8px 0;
    color: grey
}

.event-details p {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    padding: 8px 0;
    color: grey;
    font-size: 300;
}

@media screen and (max-width: 1024px) and (orientation: portrait) {

    .images-box {
        display: none;
    }

    .image-title {
        height: auto;
        width: 90%;
    }

    .titles {
        width: 100%;
    }

    .titles h1 {
        font-size: 48px;
    }

    .titles h2 {
        font-size: 16px;
    }

    .event-card {
        flex-direction: column;
        width: 90%;
        padding: 0;

    }

    .event-details {
        padding: 0;
    }

    .event-card img {
        width: 100%;
        margin-bottom: 16px;
    }

    .content-card {
        margin-bottom: 48px;
    }

}

@media screen and (max-width: 599px) {
    .titles h1 {
        margin: 0 0 15px 0
    }

    .titles h2 {
        padding: 0;
        margin: 0;
    }

    .event-details button {
        width: 40%;
        margin-top: 10px;
    }
}