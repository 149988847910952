.about-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
}

.section {
    margin-bottom: 40px;
    text-align: justify;
}

.section h2 {
    margin-bottom: 10px;
    font-size: 2.5rem;
}

.section p {
    font-size: 1.15rem;
    color: grey;
    font-weight: 300;
}

.about-page img {
    width: 100%;
    object-fit: contain;
}

.accordion-item {
    margin-bottom: 10px;
}

.accordion-button {
    color: #fff;
    padding: 10px;
    border-bottom: 'solid grey 5px';
    cursor: pointer;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

/* .accordion-button:hover {
    background-color: black;
} */

.accordion-content {
    display: none;
    padding: 10px;
}

.accordion-content.active {
    display: block;
}

.accordion-content p {
    margin: 0;
    color: grey;
    font-size: 1rem;
    text-align: justify;
    font-weight: 300;
}

.section h1 {
    text-align: justify;
    font-weight: 400;
    font-size: 3.8rem;
    margin: 0;
}

.section h4 {
    font-weight: 900;
    color: grey;
}

.section h3 {
    color: grey;
    font-weight: 300;
    font-size: 1.5rem;
}

.church-leadership {
    margin-top: 40px;
}

.leadership-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.leadership-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
}

.leader-image {
    margin: 0 auto 15px;
    display: block;
    
}

.leadership-card h3 {
    font-size: 1rem;
    margin-bottom: 5px;
    color: black;
    font-weight: 300;
}

.leadership-card p {
    color: #666;
    font-size: .8rem;
    font-weight: 300;
}


@media screen and (max-width: 599px) {
    .section h1 {
            text-align: left;
            font-size: 2.8rem;
            margin: 0;
        }
.section p {
    text-align: justify;
}
        .section h3 {
            text-align: justify;
        }
        .leadership-card h3 {
            text-align: center;
            font-size: 1.2rem;
        }

        .leadership-card p {
            font-size: 1rem;
            text-align: center;
        }

        .leadership-card {
            border-top: none ;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #ddd;
        }
}