.main-container-ministries {
    width: 100%;
    height: 150vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.content-ministries {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0 30px 0;
}

.heading-ministries {
    width: 90%;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 10px 0;
}

.heading-ministries-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.heading-ministries-column h1 {
    font-size: 4.4vw;
    font-weight: 600;
    width: 100%;
}

.heading-ministries-column h3 {
    font-size: 1.35vw;
    font-weight: 300;
    color: grey;
    text-align: justify;
    margin: 0;
}

.bridge-content-ministries {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0;
    padding: 0;
}

.bridge-content-ministries h3 {
    text-align: center;
    font-size: 18px;
    width: 100%;
    margin: 0;
    padding: 0;
    color: grey;
    font-weight: 300;
    margin-bottom: 30px;
}

.bridge-content-ministries h1 {
    text-align: center;
    font-size: 34px;
    margin: 0;
    padding: 0 0 30px 0;
    width: 80%;
}

.left-column-ministries {
    width: 40%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}

.right-column-ministries {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: column;
    align-items: end;
}

.sunday-services {
    width: 97%;
    height: 60%;
    border-radius: 10px;
    background-image: linear-gradient(rgba(54, 53, 53, 0.2), rgba(30, 29, 29, 0.7)), url('../../../assets/Worship-2-IYK.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
}


.sunday-services a:hover {
    text-align: none;
}

.description {
    height: auto;
    text-align: left;
    color: white;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 0px 0px 0px 20px;
}

.description h1 {
    padding: 0;
    margin: 0;
}

.description p {
    width: 90%;
}

.community-impact {
    width: 97%;
    height: 35%;
    background-image: linear-gradient(rgba(54, 53, 53, 0.2), rgba(0, 0, 0, 0.7)), url('../../../assets/community-impact.jpg');
    background-size: cover;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
}

.church-groups {
    width: 97%;
    height: 35%;
    border-radius: 10px;
    background-image: linear-gradient(rgba(54, 53, 53, 0.2), rgba(0, 0, 0, 0.7)), url('../../../assets/Women-IYK.jpeg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
}


.bible-study {
    width: 97%;
    height: 60%;
    border-radius: 10px;
    background-image: linear-gradient(rgba(54, 53, 53, 0.2), rgba(0, 0, 0, 0.7)), url('../../../assets/bible-study.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
}



@media (max-width: 1024px) {
    .main-container-ministries {
        width: 100%;
        height: 90vh;
    }

    .left-column-ministries {
        width: 48%;
    }

    .right-column-ministries {
        width: 48%;
    }

    .sunday-services {
        height: 49%;
    }

    .community-impact {
        height: 49%;
    }

    .church-groups {
        height: 49%;
    }

    .bible-study {
        height: 49%;
    }
}

@media (max-width: 1024px) and  (orientation: landscape) {
    .main-container-ministries {
        width: 100%;
        height: 120vw;
    }

    .left-column-ministries {
        width: 48%;
    }

    .right-column-ministries {
        width: 48%;
    }

    .sunday-services {
        height: 49%;
    }

    .community-impact {
        height: 49%;
    }

    .church-groups {
        height: 49%;
    }

    .bible-study {
        height: 49%;
    }
}

@media screen and ((max-height: 530px) and (orientation: landscape)) {
    .main-container-ministries {
        height: 90vw;
    }

    .description p {
        display: none;
    }

    .description h1 {
        font-size: 7vh;
        margin: 0;
        padding: 0;
    }

    .bridge-content-ministries h3 {
        font-size: 3vh;
        margin: 0;
        padding: 0;
    }
}

@media screen and (max-width: 530px) {
    .main-container-ministries {
        width: 100%;
        height: 160vh;
    }

    .description {
        padding: 0;
        height: 80%;
        width: 100%;
        justify-content: space-evenly;
    }

    .description h1 {
        font-size: 7vw;
        width: 60%;
        padding: 0;
        padding-left: 8px;
        margin: 0;
        height: 70px;
    }

    .description h5 {
        padding: 0;
        padding-left: 8px;
        margin: 0;
        color: rgb(200, 200, 200);
    }

    .description p {
        display: none;
    }

    .bridge-content-ministries {
        width: 80%;
        padding: 0;
        margin-bottom: 20px;
    }

    .bridge-content-ministries h3 {
        text-align: center;
        font-size: 4vw;
        width: 100%;
        padding: 10px 0 0 0;
    }

    .bridge-content-ministries h1 {
        font-size: 8vw;
        margin: 0;
        padding: 0px;
        width: 100%;
    }

    .content-ministries {
        padding: 0;
        margin: 0;
    }

    .heading-ministries {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 30px 0 15px 0;
        margin: 0;
        height: auto;
    }

    .heading-ministries-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .heading-ministries-column h1 {
        font-size: 10vw;
        font-weight: 600;
        width: 100%;
    }

    .heading-ministries-column h3 {
        font-size: 4vw;
    }
}