.main-container-two-sided-page {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 80vh;
    flex-direction: row;
    padding: 25px 0px;
}

.left-content {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-content img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 20px;
}

.right-content {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.right-content h1 {
    font-size: 6vw;
    width: 100%;
    margin: 0;
    padding: 10px;
}

.right-content p {
    font-size: 1rem;
    color: grey;
    font-weight: 300;
    margin: 0;
}

.right-content button {
    font-size: 1vw;
    background-color: transparent;
    border: 1px solid grey;
    background-color: white;
    color: black;
    padding: 10px 12px;
    font-size: 1vw;
    cursor: pointer;
    width: 180px;
    font-weight: 600;
    border-radius: 12px;
    margin: 15px 0px;
}

.right-content button:hover {
    background-color: rgb(241, 239, 239);
}

.btn-div {
    width: 100%;
}

@media (max-width: 1024px) {
    .main-container-two-sided-page {
        height: 40vh;
    }

    .left-content {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
    }

    .right-content {
        width: 50%;
    }

    .right-content p {
        font-size: .8rem;
        font-weight: 300;
    }
}

@media (max-width: 767px) {
    .main-container-two-sided-page {
        height: 90vh;
        flex-direction: column;
        padding: 10px 0 0 0;
        margin-bottom: 20px;
    }

    .left-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
    }

    .right-content {
        margin-top: 0;
        width: 90%;
        height: auto;
    }

    .right-content p {
        font-size: 1rem;
        font-weight: 300;
        padding: 0 0 10px 0;
    }

    .right-content h1 {
        font-size: 2.5rem;
    }

        .btn-div button {
            font-size: 12px;
        }
}