.main-container-about {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px 0;
}

.left-column-about {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-column-about {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-direction: column;
}

.title-about {
  height: auto;
}

.title-about h2 {
  margin: 0;
  padding: 0px 20px 10px 20px;
  font-size: 3.5rem;
  font-weight: 500;
  text-align: right;
}

.title-about h3 {
  margin: 0;
  padding: 0 20px 0 20px;
  font-size: 2rem;
  font-weight: 300;
  text-align: right;
}

.content-about {
  height: 85%;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 3%;
}

.content-about p {
  font-size: 18px;
  line-height: 1.4em;
  /* color: rgb(71, 69, 69); */
  font-weight: 300;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
}

.content-about p:first-child {
  padding-top: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content-about::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content-about {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.lead-pastor {
  padding-bottom: 10px;
  width: 100%;
  margin: 0 15px;
  display: flex;
  justify-content: right;
}

.lead-pastor button {
  margin: 0 30px;
}

.lead-pastor h3 {
  margin: 0 0 10px 0;
  padding: 0;
  font-weight: 700;
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
}

.lead-pastor h4 {
  margin: 0;
  padding: 0;
  font-weight: 800;
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
}

.pastor-image {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.pastor-image img {
  height: auto;
  border-radius: 50%;
  width: 100%;
}

.bible-verse {
  height: 40%;
  width: 80%;
}

.bible-verse h1 {
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 4.5rem;
  height: 18%;
  color: rgb(92, 89, 89);
}

.bible-verse p {
  text-align: left;
  margin: 0;
  padding: 15px 0 0 0;
  padding-left: 25px;
  font-size: 2.2rem;
  font-weight: 300;
  color: gray;

}

.bible-verse h3 {
  text-align: left;
  margin: 0;
  padding: 20px 0;
  font-weight: 300;
  font-size: 1.5rem;
  color: gray;
}

@media (max-width: 1024px) {
  .main-container-about {
    column-gap: 0;
    padding: 0;
    margin: 0;
    height: 70%;
  }

  .left-column-about {
    width: 50%;
    justify-content: center;
  }

  .right-column-about {
    width: 50%;
  }

  .pastor-image {
    width: 100%;
    object-fit: contain;
    height: 50%;
  }

  .pastor-image img {
    height: auto;
    width: 80%;
  }

  .bible-verse {
    height: 30%;
    padding: 0 5vw;
  }

  .bible-verse p {
    text-align: justify;
  }

  .title-about {
    width: 100%;
  }

    .lead-pastor {
      justify-content: left;
      padding: 0;
      width: 100%;
      height: 50px;
      margin: 20px 0;
    }
    .lead-pastor button {
      margin: 0;
      font-weight: 500;
    }

    .lead-pastor a {
      text-decoration: none;
      color: white;
    }
}

@media (max-width: 1024px) or ((max-height: 599px) and (orientation: landscape)) {
  .main-container-about {
    column-gap: 0;
    padding: 0;
    margin: 0;
    flex-direction: column-reverse;
    height: 100%;
  }

  .left-column-about {
    width: 90%;
    justify-content: center;
    height: 40%;
    flex-direction: row;
    padding: 5%;
  }

  .right-column-about {
    width: 90%;
    height: 50%;
    padding: 2.5%;
  }

  .pastor-image {
    display: none;
  }

  .title-about {
    height: 12%;
  }

  .title-about h2 {
    text-align: left;
    font-size: 3.5rem;
    font-weight: 500;
    padding: 0;
  }

  .title-about h3 {
    text-align: left;
    margin: 0;
    padding: 10px 0 0 0;
    font-size: 2rem;
    font-weight: 300;
  }

  .content-about p {
    font-size: 16px;
    line-height: 1.4em;
    font-weight: 330;
    text-align: justify;
    height: 50%;
    padding: 0;
  }

  .lead-pastor h3 {
    text-align: left;
    padding: 0;
  }

  .lead-pastor h4 {
    text-align: left;
    padding: 0;
  }

  .bible-verse {
    width: 100%;
  }

  .bible-verse h1 {
    font-size: 3rem;
  }

  .bible-verse p {
    font-size: 1.8rem;
    padding: 0 15px;

  }

  .bible-verse h3 {
    font-size: 1.2rem;
    width: 100%;
  }
}