.main-container-services {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin: 0 0 30px 0;
}

.heading-service {
    width: 80%;
    height: 60vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    flex-direction: row;
}

.left-column-service {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.left-column-service-content {
    width: 40%;
    height: 45%;
    margin: 1.5%;
    border-radius: 8px;
    object-fit: cover;
}

.right-column-service {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.right-column-service h1 {
    text-align: left;
    font-size: clamp(2rem, 3vw, 4vh);
    width: 80%;
    margin: 0;
    padding: 0;
}

.right-column-service p {
    font-size: 1rem;
    width: 80%;
    margin-top: 5px;
    padding: 0;
    text-align: justify;
    color: grey;
}

.main-container-services-items {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}

.service-item {
    width: 25%;
    height: 100%;
    margin: 2%;
}

.service-item img {
    width: 100%;
    height: 50%;
    object-fit: cover;
}

.service-item h1 {
    font-size: 2.5vw;
    padding: 8px 0px 4px 0px;
    margin: 0;
}

.service-item p {
    font-size: 1vw;
    color: rgb(146, 144, 144);
    font-weight: 300;
    padding: 8px 0px;
    margin: 0;
    text-align: justify;
}

.service-item button {
    font-size: 1vw;
    background-color: transparent;
    border: 1px solid grey;
    background-color: white;
    color: black;
    padding: 10px 12px;
    font-size: 1vw;
    cursor: pointer;
    margin-top: 8px;
    width: 180px;
    font-weight: 600;
    border-radius: 12px;
}

.service-item button:hover {
    background-color: rgb(241, 239, 239);
}

.ministries-bible-verse {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.ministries-bible-verse p {
    font-size: 1.5rem;
    color: gray;
    text-align: justify;
    width: 60%;
    font-weight: 500;
}

.ministries-bible-verse h5 {
    text-align: center;
    width: 30%;
    font-size: 2rem;
}

@media (max-width: 1024px) {
    .heading-service {
        width: 80%;
        height: 40vh !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0px 0px 0px;
    }

    .left-column-service {
        width: 45%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%;
    }


    .right-column-service {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
    }

    .right-column-service h1 {
        text-align: left;
        font-size: 4rem;
        width: 80%;
        margin: 0;
        padding: 0;
    }

    .right-column-service p {
        font-size: 1.0rem !important;
        width: 80%;
        margin-top: 5px;
        padding: 0;
        text-align: justify;
        color: grey;
    }

    .ministries-bible-verse p {
        font-size: 1.2rem;
    }

    .ministries-bible-verse h5 {
        font-size: 1.2rem;
    }

    .service-item p {
        font-size: .7rem;
    }
}

@media screen and (max-height: 559px) and (orientation: landscape) {
    .heading-service {
        width: 80%;
        height: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0px 0px 0px;
        flex-direction: row;
        padding: 0;
    }

    .left-column-service {
        display: none;
    }

    .right-column-service {
        width: 100%;
        flex-direction: row;
    }

    .right-column-service p {
        font-size: 1.8vw;
        padding: 0;
        margin: 0;
    }

    .right-column-service h1 {
        font-size: 7vw;
        width: 80%;
    }

    .ministries-bible-verse {
        display: none;
    }

}

@media screen and (max-width: 599px) {
    .ministries-bible-verse {
        display: none;
    }

    .left-column-service {
        display: none;
    }

    .heading-service {
        height: 20vh !important;
    }

    .right-column-service {
        width: 100%;
    }

    .right-column-service p {
        display: none;
    }

    .right-column-service h1 {
        width: 100%;
    }

    .right-column-service h1 {
        font-size: 3rem;
        margin: 20px 0;
        padding: 0;
        width: 100%;
    }

    .main-container-services {
        margin: 0;
    }

    .heading-service {
        margin: 0;
        width: 90%;
        height: 50%;
    }

    .service-item {
        width: 100%;
        height: 100%;
        margin: 2% 0;
        margin-bottom: 50px;
    }

    .service-item h1 {
        font-size: 1.8rem;
    }

    .service-item p {
        font-size: 1rem;
    }

    .service-item button {
        font-size: .8rem;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .right-column-service p {
        font-size: 1.5vw;
        padding: 0;
        margin: 0;
    }

    .right-column-service h1 {
        font-size: 6vw;
        width: 80%;
    }

}

@media screen and (min-width: 1024px) and (orientation: landscape) {
    .heading-service {
            height: 40vw;
        }
    
    .right-column-service p {
        font-size: 1.5vw;
        padding: 0;
        margin: 0;
    }

    .right-column-service h1 {
        font-size: 6vw;
        width: 80%;
    }

}