.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

}

.blog-post-details {
    margin-bottom: 20px;
}

.blog-post-title {
    font-size: 3rem;
    margin-bottom: 5px;
}

.blog-post-author,
.blog-post-date {
    font-size: 16px;
    margin-bottom: 5px;
    color: grey;
}

.blog-post-content img {
    width: 100%;
    margin-bottom: 20px;
}

.blog-post-content div {
    font-size: 18px;
    color: black;
    font-weight: 300;
}

@media (max-width: 600px) {
    .blog-post-title {
        font-size: 24px;
    }

    .blog-post-author,
    .blog-post-date {
        font-size: 14px;
    }
}