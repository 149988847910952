    .main-container-newsletter {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 0 45px 0;
    }

    .left-column-newsletter {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .right-column-newsletter {

        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .buffer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;
        width: 100%;
    }

    .centered-content-heading {
        width: 80%;
    }

    .centered-content-subheading {
        width: 60%;
        padding: 0;
        margin: 0;
        transform: translateY(-2.5rem);
    }

    .centered-content-heading h1 {
        font-weight: 600;
        font-size: 2.5rem;
        color: rgb(123, 121, 121);
        line-height: 55px;
        text-align: center;
    }

    .centered-content-subheading h4 {
        color: grey;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .article {
        height: 100%;
        width: 48%;
        cursor: pointer;
    }

    .article img {
        height: 75%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .article-description {
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
    }

    .article-description p {
        font-size: 16px;
        text-align: left;
        margin: 0;
        overflow: hidden;
        padding: 4px;
        padding-left: 2px;
        font-weight: 650;
    }

    .article-description h4 {
        font-size: 8px;
        text-align: left;
        height: 15%;
        margin: 0;
        margin-top: 5px;
        overflow: hidden;
    }

    .newsletter {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: none;
        border-radius: 8px;
    }

    /* .right-column-newsletter p {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    background-color: red;
}

.right-column-newsletter h1 {
    text-align: left;
    height: 0;
} */

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: 49%;
        width: 100%;
    }

    .newsletter-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100%;
    }

    .newsletter-title h1 {
        font-size: 4rem;
        font-weight: 500;
        padding: 0;
        margin: 0;
        color: black;
        width: 100%;
        padding: 20px 0;
        text-align: right;
    }

    .newsletter-title p {
        font-size: 1.35vw;
        font-weight: 300;
        color: grey;
        text-align: justify;
        margin: 0;
        padding: 0;
    }

    .button-submit-newsletter {
        width: 100%;
        color: white;
        justify-content: right;
        height: 10%;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .button-submit-newsletter a {
        text-decoration: none;
        color: white;
        width: 35%;
        padding: 0px !important;
        margin-top: 30px;
    }

    .button-submit-newsletter button {
        height: 40px;
        width: 100%;
        color: white;
        text-align: center;
        font-size: 1rem;
        background-color: black;
        border: 0;
        cursor: pointer;
        font-weight: 530;
        border-radius: 4px;
        margin: 0;
        padding: 0px 10px;
    }

    .button-submit-newsletter button:hover {
        background-color: green;
    }


    @media (max-width: 1024px) {
        .main-container-newsletter {
            height: 40vh;
            column-gap: 0;
            justify-content: space-around;
        }

        .left-column-newsletter {
            width: 40%;
            align-items: center;
        }

        .right-column-newsletter {
            width: 50%;
        }

        .newsletter-title h1 {
            font-size: 6.5vw;
            font-weight: 500;
            padding: 0;
            margin: 0;
            color: black;
            text-align: right !important;
            margin-bottom: 10px;
        }
.newsletter-title p {
    font-size: 1.9vw;
}
        .newsletter-title a {
            color: black;
            height: 20%;
            width: 70%;
            padding-top: 10px;
        }

        .centered-content-subheading {
            width: 80%;
            text-align: center;
        }

        .centered-content-heading {
            text-align: center;
        }

        .centered-content-heading h1 {
            font-weight: 600;
            font-size: 2.2rem;
            color: rgb(123, 121, 121);
            line-height: 5vw;
            margin-bottom: 5vw;
        }
                .button-submit-newsletter {
                    justify-content: flex-end;
                }
    }

        @media (max-width: 1024px) and (orientation: landscape) {
            .main-container-newsletter {
                height: 50vw;
            }
    
            .right-column-newsletter {
                width: 50%;
            }
    
        }

    @media (max-width: 599px) {
        .main-container-newsletter {
            height: 550px;
            flex-direction: column;

            margin: 15px 0 60px 0;
        }

        .left-column-newsletter {
            width: 90%;
            align-items: flex-start;

            height: auto;
        }

        .right-column-newsletter {
            width: 90%;
        }

        .newsletter-title {
            width: 100%;
        }

        .newsletter-title h1 {
            font-size: 8vw;
            text-align: left !important;
            width: 100%;
        }

        .newsletter-title p {
            font-size: 4vw;
            width: 100%;
            text-align: left;
            padding: 10px 0;
        }

        .centered-content-subheading {
            width: 80%;
            text-align: center;
        }

        .centered-content-heading {
            text-align: center;
            width: 80%;
        }

        .centered-content-heading h1 {
            font-size: 1.5rem;
            margin-bottom: 10px;
            line-height: 40px;
            height: 50%;
            text-align: center;
        }

        .centered-content-subheading h4 {
            display: none;
        }

        .buffer-container {
            height: 25vh;
        }

        .button-submit-newsletter {
            width: 100%;
            justify-content: flex-start;
            padding: 0;
            margin: 0;
        }

        .button-submit-newsletter a {
            text-decoration: none;
            color: white;
            width: 35%;
        }

        .button-submit-newsletter button {
            font-size: 4vw;
        }
    }

    @media ((max-height: 559px) and (orientation: landscape)) {
        .main-container-newsletter {
            height: 50vw;
            flex-direction: row;
            width: 100%;
            padding: 0;
        }

        .right-column-newsletter {
            width: 45%;
        }

        .newsletter-title h1 {
            margin: 5% 0;
            text-align: left;
        }

        .newsletter-title p {
            font-size: 4vh;
        }

        .button-submit-newsletter {
            margin: 0;
            padding: 0;
        }
    }