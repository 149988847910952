body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
}

.posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.posts h1 {
    width: 75%;
    font-size: 6rem;
    border-bottom: solid black 3px;
    text-align: center;
    padding: 0;
    margin: 0 0 50px 0;
}

.post {
    width: 100%;
    margin-bottom: 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: pointer;
    align-items: center;
}

.post-link-article {
    text-decoration: none;
    width: 80%;
}

.post-details {
    margin-bottom: 20px;
    width: 60%;
}

.post-title {
    font-size: 24px;
    margin-bottom: 5px;
    color: black;
}

.post-author {
    font-size: 14px;
    margin-bottom: 5px;
    color: grey;
    font-weight: 400;
}

.post-summary {
    font-size: 16px;
    color: grey;
    font-weight: 300;
}



.post-image {
    width: 25%;
}

.post-image img {
    width: 100%;
    border-radius: 8px;
}

.pagination {
    margin-top: 20px;
    text-align: center;
}

.pagination a {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f9f9f9;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 5px;
}

.pagination a:hover {
    background-color: #ddd;
}

.line-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line-div hr {
    width: 95%;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #F2F2F2;
    margin: 1.5em 0;
    padding: 0;
}

.pagination {
    margin: 0 0 30px 0;
}

@media screen and (max-width: 1024px) {
    .post-image {
        width: 32%;
    }

    .post-summary {
        width: 95%;
    }
}

@media screen and (max-width: 599px) {
    .post {
        flex-direction: column-reverse;
        width: 100%;
    }

    .posts {
        width: 100%;
        padding: 0;
    }

    .posts h1 {
        font-size: 4rem;
        text-align: left;
        width: 90%;
        padding: 20px 0;
        border-bottom: solid black 3px;
    }

    .post-details {
        width: 100%;
        padding: 0;
    }

    .post-image {
        width: 100%;
    }

    .line-div {
        display: none;
    }

    .container {
        padding: 0;
        margin: 0px;
    }

    .post-link-article {
        width: 90%;
        margin: 10px 0;
    }

    .post-summary {
        width: 100%;
    }
}