.main-container-footer {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(222, 220, 220);
    border-radius: 0;
    background-color: #f2f2f2;
}

.left-column-footer {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.middle-column-footer {
    width: 1.5px;
    background-color: grey;
    height: 100%;

}

.right-column-footer {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading-footer {
    width: 90%;
}

.heading-footer h1 {
    font-size: 4rem;
    text-align: left;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    color: black;
}

.heading-footer h3 {
    padding: 0;
    margin: 0;
    text-align: left;
    color: rgb(34, 34, 34);
    color: black;
    font-weight: 500;
}

.icon-container-footer {
    width: 60%;
    height: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.icon-container-column-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon-container-column-footer h5 {
    padding: 0;
    margin: 10px;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    color: black;
    color: black;
    text-align: left;
}

.social-media-icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin: 20px 0 0 0;
}

.social-media-icons img {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
}
.icon-container-column-footer a {
    text-decoration: none;
}

.icon-container-column-footer h5:hover {
    font-weight: bolder;
}

@media (max-width: 1024px) {

    .main-container-footer {
        width: 100%;
        height: 50vh;
    }

    .heading-footer h1 {
        font-size: 5vw;
        font-weight: 500;
        width: 100%;
    }

    .heading-footer h3 {
        font-size: 2.5vw;
    }

    .heading-footer a {
        font-size: 2.0vw;
        color: black;
        color: black;
    }

    .icon-container-footer {
        width: 80%;
    }

    .icon-container-column-footer h5 {
        font-size: 2vw;
        font-weight: 500;
    }

    .icon-container-column-footer {
        width: auto;
    }
}

@media screen and (max-height: 768px) and (orientation: landscape) {
    .main-container-footer {
        width: 100%;
        height: 60vw;
    }
}

@media (max-width: 767px) {
    .main-container-footer {
        width: 100%;
        height: 90%;
        flex-direction: column;
        padding: 50px 0;
    }

    .heading-footer h1 {
        font-size: 2.2rem;
        font-weight: 500;
        width: 100%;
    }

    .left-column-footer {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 5%;

    }


    .right-column-footer {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .heading-footer h3 {
        font-size: 1rem;
        width: 80%;
    }

    .heading-footer a {
        font-size: .9rem;
        color: black;
        color: black;
    }

    .icon-container-footer {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

    }

    .icon-container-column-footer h5 {
        font-size: 1rem;
        text-align: left;
        font-weight: bold;
        padding: 0;
        margin: 10px 0;
    }

    .icon-container-column-footer {
        align-items: flex-start;
        justify-content: center;
        width: 90%;
        margin: 10px 0;
    }

    .social-media-icons {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0;
        margin: 20px 0 0 0;
    }

    .social-media-icons a {
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
        border-radius: 20px;
        padding: 0;
    }

    .social-media-icons img {
        padding: 0;
        margin: 0;
    }

}