.responsive-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 50px;
}
.form-header {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: justify;
}
.form-header img {
    width: 100%;
    height: 20%;
    object-fit: contain;
}
.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    font-weight: 500;

    color: grey;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    width: 95%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px 0 0 0;
}

textarea {
    height: 180px;
    resize: vertical;
}

button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

button:hover {
    background-color: green;
}

.error {
    color: red;
}
.success {
    height: 600px;
    font-size: 1em;
    margin-top: 1em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.success button {
    margin: 30px 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.modal h2 {
    margin-bottom: 20px;
}

.modal button {
    padding: 10px 20px;
    background: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

@media  screen and (max-width: 599px) {
    .form-header {
        font-size: 1.8rem;
        font-weight: 500;
    }
}