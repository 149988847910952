.main-container-media {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.left-column-media {
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: auto;
    justify-content: space-evenly;
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
    gap: 20px;
}

::-webkit-scrollbar {
    height: 3px;
    width: 4px;
    background: rgb(222, 220, 220);
}

::-webkit-scrollbar-thumb:horizontal {
    background: black;
    width: 50px;
}

.right-column-media {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: end; */
}

.articles {
    width: 45%;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin: 0;
}

.articles img {
    height: 82%;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.articles p {
    font-size: 18px;
    text-align: left;
    margin: 0;
    overflow: hidden;
    padding: 10px 0;
    font-weight: 600;
    color: rgb(44, 41, 41);
}

.article-bible-verse h1 {
    font-size: 4rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
    color: black;
    width: 100%;
    padding: 20px 0;
}

.article-bible-verse p {
    font-size: 1.35vw;
    font-weight: 300;
    color: grey;
    text-align: justify;
    margin: 0;
    padding: 0 0 10px 0;
    width: 80%;
}

.button-submit-article {
    width: 100%;
    color: white;
    height: 10%;
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
    padding: 0;
}

.button-submit-article a {
    text-decoration: none;
    color: white;
    width: 35%;
    padding: 0px !important;
    margin-top: 30px;
}

.button-submit-article button {
    height: 40px;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 1rem;
    background-color: black;
    border: 0;
    cursor: pointer;
    font-weight: 530;
    border-radius: 4px;
    margin: 0;
    padding: 0px 10px;
}

.button-submit-article button:hover {
    background-color: green;
}

@media (max-width: 1024px) {
    .main-container-media {
        height: 40vh;
        column-gap: 0;
        justify-content: space-around;
        align-items: center;
        padding: 10px 0;
    }

    .left-column-media {
        width: 60%;
        align-items: center;
    }

    .right-column-media {
        width: 28%;
    }

    .article-bible-verse h1 {
        font-size: 6.5vw;
        font-weight: 500;
        padding: 0;
        margin: 0;
        color: black;
        text-align: right;
        margin-bottom: 10px;
    }

    .sermon-title a {
        color: black;
        height: 20%;
        width: 70%;
        padding-top: 10px;
    }

    .article-bible-verse a {
        color: black;
        height: 20%;
        width: 70%;
        padding: 0px !important;
        margin-top: 5px;
    }

    .article-bible-verse p {
        font-size: 1.9vw;
        width: 100%
    }

    .article-bible-verse h1 {
        text-align: left;
    }

    .button-submit-article a {
        padding: 0px !important;
        margin-top: 5px;
    }

}

@media (max-width: 1024px) and (orientation: landscape) {

    .articles p {
        font-size: .9rem;
    }
}

@media (max-width: 599px) {
    .main-container-media {
        height: 550px;
        column-gap: 0;
        justify-content: space-around;
        align-items: center;
        flex-direction: column-reverse;
        width: 100%;
        margin: 40px 0 0 0;
    }

    .left-column-media {
        width: 90%;
        gap: 25px;
        margin: 0;
    }

    .right-column-media {
        width: 90%;
        padding: 10px 0;
        height: 40%;
        margin: 10px 0 0 0;
    }

    .article-bible-verse h1 {
        font-size: 10vw;
        width: 100%;
        text-align: left;
        padding: 0;
        margin: 0;
    }

    .article-bible-verse p {
        font-size: 4vw;
        width: 100%;
        text-align: left;
        padding: 10px 0;
        margin: 0;
        color: grey;

    }

    .article-bible-verse h3 {
        font-size: 4vw;
    }

    .sermon-title a {
        color: black;
        height: 20%;
        width: 70%;
        padding-top: 10px;
    }

    .article-bible-verse a {
        color: black;
        height: 20%;
        width: 70%;
        padding-top: 10px;
    }

    .articles {
        width: 60%;
        padding: 0;
        margin: 0;
        height: 100%;
    }

    .articles img {
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        padding: 0;
        margin: 0;
        height: 80%;
    }

    .articles p {
        font-size: 4vw !important;
        text-align: left;
        max-lines: 2;
        color: grey;
        font-weight: 500;
        width: 100%;
    }

    .button-submit-article {
        margin: 0;
        padding: 0;
    }

    .button-submit-article a {
        text-decoration: none;
        color: white;
        width: 35%;
        padding: 0;
        margin: 0;
    }

    .button-submit-article button {
        font-size: 3.5vw;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .main-container-media {
        height: 450px;
        padding: 0;
        margin: 0;
    }

    .articles {
        width: 50%;
        padding: 0;
        margin: 0;
        height: 100%;
    }

    .articles img {
        height: 85%;
    }

    .articles p {
        font-size: 2.5vh;
        text-align: left;
        max-lines: 2;
    }

    .article-bible-verse h1 {
        font-size: 9vh;
        width: 100%;
        text-align: left;
        padding: 10px 0;
    }

    .article-bible-verse p {
        font-size: 1rem;
        width: 90%;
    }

    .button-submit-article {
        margin: 0;
        padding: 0;
    }
}