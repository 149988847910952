.event-item-container {
    max-width: 820px;
    margin: 0 auto;
    padding: 20px;
}

.event-item-container h2 {
    margin-bottom: 20px;
    color: grey;
}
.event-item-image {
    margin-bottom: 20px;
}

.event-item-image img {
    width: 100%;
}

.event-item-details {
    margin-bottom: 20px;
}

.event-item-title {
    font-size: 32px;
    margin-bottom: 5px;
}

.event-item-date,
.event-item-location {
    font-size: 16px;
    margin-bottom: 5px;
}

.event-item-description {
    font-size: 16px;
}

@media (max-width: 600px) {
    .event-item-title {
        font-size: 24px;
    }

    .event-item-date,
    .event-item-location,
    .event-item-description {
        font-size: 14px;
    }
}