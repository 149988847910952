    .main-container-events {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 45px 0;
    }

    .left-column-events {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .right-column-events {

        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .buffer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;
        width: 100%;
    }

    .centered-content-heading {
        width: 80%;
    }

    .centered-content-subheading {
        width: 60%;
        padding: 0;
        margin: 0;
        transform: translateY(-2.5rem);
    }

    .centered-content-heading h1 {
        font-weight: 600;
        font-size: 2.5rem;
        color: rgb(123, 121, 121);
        line-height: 55px;
        text-align: center;
    }

    .centered-content-subheading h4 {
        color: grey;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .events {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: none;
        border-radius: 8px;
    }

    .events-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100%;
    }

    .events-title h1 {
        font-size: 4rem;
        font-weight: 500;
        margin: 0;
        color: black;
        width: 100%;
        padding: 20px 0;
    }

    .events-title p {
        font-size: 1.35vw;
        font-weight: 300;
        color: grey;
        text-align: justify;
        margin: 0;
        padding: 0;
    }

    .button-submit-events {
        width: 100%;
        color: white;
        justify-content: left;
        height: 10%;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .button-submit-events a {
        text-decoration: none;
        color: white;
        width: 35%;
        padding: 0px !important;
        margin-top: 30px;
    }

    .button-submit-events button {
        height: 40px;
        width: 100%;
        color: white;
        text-align: center;
        font-size: 1rem;
        background-color: black;
        border: 0;
        cursor: pointer;
        font-weight: 530;
        border-radius: 4px;
        margin: 0;
        padding: 0px 10px;
    }

    .button-submit-events button:hover {
        background-color: green;
    }


    @media (max-width: 1024px) {
        .main-container-events {
            height: 40vh;
            column-gap: 0;
            justify-content: space-around;
        }

        .left-column-events {
            width: 40%;
            align-items: center;
        }

        .right-column-events {
            width: 50%;
        }

        .events-title h1 {
            font-size: 6.5vw;
            font-weight: 500;
            padding: 0;
            margin: 0;
            color: black;
            text-align: left;
            margin-bottom: 10px;
        }

        .events-title a {
            color: black;
            height: 20%;
            width: 70%;
            padding-top: 10px;
        }

        .events-title p {
            font-size: 1.9vw;
        }
    }

    @media (max-width: 1024px) and (orientation: landscape) {
        .main-container-events {
            height: 50vw;
            justify-content: space-around;
            gap: 0;
        }

        .right-column-events {
            width: 50%;
        }

        .events-title h1 {
            text-align: left;
        }
    }

    @media (max-width: 599px) {
        .main-container-events {
            height: 550px;
            flex-direction: column-reverse;
            margin: 30px 0 60px 0;
        }

        .left-column-events {
            width: 90%;
            height: auto;
            padding: 0;
            margin: 0;
        }

        .right-column-events {
            width: 90%;
        }

        .events-title {
            width: 100%;
        }

        .events-title h1 {
            font-size: 10vw;
            text-align: left;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        .events-title p {
            font-size: 4vw;
            width: 100%;
            text-align: left;
            padding: 10px 0;
        }

        .button-submit-events {
            width: 100%;
            justify-content: left;
            padding: 10px 0;
        }

        .button-submit-events a {
            text-decoration: none;
            color: white;
            width: 35%;
        }

        .button-submit-events button {
            font-size: 3.5vw;
        }
    }

    @media ((max-height: 559px) and (orientation: landscape)) {
        .main-container-events {
            height: 50vw;
            flex-direction: row;
            width: 100%;
            padding: 0;
        }

        .right-column-events {
            width: 45%;
        }

        .events-title h1 {
            margin: 5% 0;
            text-align: left;
        }

        .events-title p {
            font-size: 4vh;
        }

        .button-submit-events {
            margin: 0;
            padding: 0;
        }
    }