.main-container-brigde {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.heading-brigde {
    width: 90%;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
}

.heading-brigde-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.heading-brigde-column h1 {
    font-size: 4.4vw;
    font-weight: 600;
    width: 85%;
    text-align: right;
}

.heading-brigde-column h3 {
    font-size: 1.35vw;
    font-weight: 300;
    color: grey;
    text-align: justify;
    margin: 0;
}

@media screen and (max-width: 599px) {
    .main-container-brigde {
        width: 100%;
        height: 60%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        margin: 25px 0;
    }

    .heading-brigde {
        width: 90%;
        flex-direction: column-reverse;
        margin: 0;
        padding: 0;
    }

    .heading-brigde-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .heading-brigde-column h1 {
        font-size: 10vw;
        font-weight: 600;
        width: 100%;
        text-align: left;
        padding: 0;
        margin: 10px 0;
    }

    .heading-brigde-column h3 {
        font-size: 4vw;
        font-weight: 300;
        color: grey;
        text-align: justify;
        margin: 0;
        padding-bottom: 10px;
    }
}